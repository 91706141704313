var render = function render(){var _vm=this,_c=_vm._self._c;return _c('li',[_c('p',[_vm._v("Author: '"+_vm._s(_vm.moderatedMessage.Message.Payload.Sender)+"'")]),_c('CanvasMessage',{attrs:{"msg":_vm.moderatedMessage.msg}}),_c('ul',{attrs:{"id":"options"}},[_c('li',{class:{ btn: true, selected: _vm.newState == 'visible' }},[_c('font-awesome-icon',{class:{
          icn: true,
          disabled: _vm.disabled,
          active: _vm.moderatedMessage.State == 'visible'
        },attrs:{"icon":"eye","title":"make visible"},on:{"click":function($event){_vm.disabled ? null : (_vm.newState = 'visible')}}})],1),_c('li',{class:{ btn: true, selected: _vm.newState == 'invisible' }},[_c('font-awesome-icon',{class:{
          icn: true,
          disabled: _vm.disabled,
          active: _vm.moderatedMessage.State == 'invisible'
        },attrs:{"icon":"eye-slash","title":"make invisible"},on:{"click":function($event){_vm.disabled ? null : (_vm.newState = 'invisible')}}})],1),_c('li',{class:{ btn: true, selected: _vm.newState == 'deleted' }},[_c('font-awesome-icon',{class:{ icn: true, disabled: _vm.disabled },attrs:{"icon":"times","title":"delete"},on:{"click":function($event){_vm.disabled ? null : (_vm.newState = 'deleted')}}})],1),_c('li',{class:{ btn: true, selected: _vm.newState == 'offensive' }},[_c('font-awesome-icon',{class:{ icn: true, disabled: _vm.disabled },attrs:{"icon":"exclamation-triangle","title":"ban"},on:{"click":function($event){_vm.disabled ? null : (_vm.newState = 'offensive')}}})],1),_c('div',{staticClass:"sep"}),_c('li',{staticClass:"btn",attrs:{"id":"submit"}},[_c('font-awesome-icon',{class:{
          icn: true,
          disabled: _vm.disabled || _vm.newState == _vm.moderatedMessage.State
        },attrs:{"icon":"share","title":"submit"},on:{"click":function($event){!_vm.disabled && _vm.newState != _vm.moderatedMessage.State ? _vm.submit() : null}}})],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }