var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"moderate"},[_c('div',{staticClass:"container"},[_c('header',[(_vm.token)?_c('span',{attrs:{"id":"sign-out"},on:{"click":_vm.discardToken}},[_c('font-awesome-icon',{attrs:{"icon":"sign-out-alt","title":"sign out"}}),_vm._v(" Sign Out ")],1):_vm._e(),_c('h1',[_vm._v("Moderation dashboard")]),(_vm.token)?_c('font-awesome-icon',{staticClass:"icn",class:{ active: this.refreshing },attrs:{"id":"refresh","disabled":_vm.refreshing,"icon":"redo-alt"},on:{"click":_vm.refresh}}):_vm._e()],1),_c('hr'),(_vm.token === null)?_c('AuthForm',{on:{"authenticated":_vm.setToken}}):_c('div',{attrs:{"id":"dashboard"}},[_c('div',{attrs:{"id":"controlPanel"}},[_c('RoomList',{ref:"roomList",attrs:{"id":"roomList","token":_vm.token,"rooms":_vm.rooms,"selectedRoom":_vm.selectedRoom,"disabled":_vm.refreshing},on:{"select":room => {
              this.selectedRoom = room;
              this.refresh();
            }}}),(_vm.selectedRoom)?_c('StateList',{ref:"stateList",attrs:{"id":"stateList","selectedState":_vm.selectedState,"selectedRoom":_vm.selectedRoom,"disabled":_vm.refreshing},on:{"select":state => {
              this.selectedState = state;
              this.refresh();
            }}}):_vm._e()],1),_c('div',{attrs:{"id":"moderatedMessages"}},[(_vm.selectedState && _vm.selectedRoom)?_c('strong',[_vm._v(" "+_vm._s({ invisible: "Invisible", visible: "Visible" }[_vm.selectedState])+" Moderated messages in room '"+_vm._s(_vm.selectedRoom.Name)+"': ")]):(!_vm.selectedRoom)?_c('strong',[_vm._v("Select a room.")]):(!_vm.selectedState)?_c('strong',[_vm._v("Select a state.")]):_vm._e(),_c('hr'),(_vm.selectedRoom && _vm.selectedState)?_c('ModeratedMessageList',{ref:"moderatedMessageList",attrs:{"id":"moderatedMessageList","token":_vm.token,"selectedRoomName":_vm.selectedRoom.Name,"selectedState":_vm.selectedState,"disabled":_vm.refreshing},on:{"refresh":_vm.refresh}}):_vm._e()],1)])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }